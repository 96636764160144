import { SimpleShowLayout, Show, TextField, DeleteButton } from 'react-admin';

export const FeedbackShow = () => (
	<Show>
		{/* <DeleteButton mutationMode="pessimistic" /> */}
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="name" fullWidth label="Name" />
			<TextField source="email" fullWidth label="User email" />
			<TextField source="creationDate" fullWidth label="Date created" />
			<TextField source="subject" fullWidth label="Subject" />
			<TextField source="message" fullWidth label="Message" />
		</SimpleShowLayout>
	</Show>
);
