import { Card, CardContent } from '@mui/material';
import { Datagrid, FilterList, FilterListItem, FilterLiveSearch, List, useGetList, NumberField, Pagination, SavedQueriesList, SearchInput, TextField, TextInput, BulkActionsToolbar, BulkUpdateButton, Button, useListContext, } from 'react-admin';

import MailIcon from '@mui/icons-material/MailOutline';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import { DateChart } from './charts/date.chart';
import { useEffect } from 'react';


const AnalyticsFilter = (props: any) => {

    return (
        <Card sx={{ order: -1, mr: 2, mt: 8, width: 200 }}>
            <CardContent>
                <SavedQueriesList />
                <FilterLiveSearch source='chatbot.name' autoComplete='true'></FilterLiveSearch>
                <FilterList label="Bots" icon={<MailIcon />} defaultValue={2}>
                    <FilterListItem label="All" value={{ bots: "all" }} />
                    <FilterListItem label="Clients" value={{ bots: "clients" }} />
                    <FilterListItem label="Test" value={{ bots: "test" }} />
                </FilterList>
                <FilterList label="Date Range" icon={<CalendarMonth />} defaultValue={3} >
                    <FilterListItem label="Day" value={{ interval: 'day' }} />
                    <FilterListItem label="Month" value={{ interval: 'month' }} />
                    <FilterListItem label="Year" value={{ interval: 'year' }} />
                </FilterList>
            </CardContent>
        </Card >
    )
}

export function AnalyticsList(props: any) {

    const list = useGetList("admin/chatbot/analytics")
    // const selectedIds = useListContext(props);

    useEffect(() => {
        console.log(list)
    }, [list,])


    const handleCustomAction = (e: any) => {
        console.log(e)
    }

    const CustomBulkActionsToolbar = () => {
        const { selectedIds } = useListContext();

        const handleCustomAction = () => {
            // Access the selectedIds array to get the selected rows
            console.log('Selected Rows:', selectedIds);
        };

        return (
            <BulkActionsToolbar>
                <Button onClick={handleCustomAction} label='Custom'></Button>
            </BulkActionsToolbar>
        );
    };

    return (
        <>
            <h1>Analytics</h1>
            <DateChart />

            <List
                {...props}
                aside={<AnalyticsFilter />}
                filterDefaultValues={{ bots: 'all', interval: 'month' }}
                resource="admin/chatbot/analytics"
                pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 150, 200]} />}
            >
                <Datagrid rowClick="show"
                    //  bulkActionButtons={<BulkActionsToolbar>
                    //     <Button label='custom' onClick={handleCustomAction}></Button>
                    //     <BulkUpdateButton></BulkUpdateButton>
                    // </BulkActionsToolbar>}

                    bulkActionButtons={<CustomBulkActionsToolbar />}
                >
                    <TextField source="chatbot.name" sortable />
                    <NumberField source='requests' />
                    <NumberField source='scriptRequests' />
                </Datagrid>
            </List>

        </>
    );

}
