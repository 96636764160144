import {
  SimpleShowLayout,
  TabbedShowLayout,
  Tab,
  Show,
  TextField,
  UrlField,
  FileField,
  ImageField,
  BooleanField,
  NumberField,
  DateField,
  ArrayField,
  Datagrid,
  Pagination,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const ChatBotShow = () => (
  <Show>
    <div style={{ position: 'relative' }}>
      <div
        style={{
          margin: '16px',
          position: 'absolute',
          bottom: 0,
          right: 0,
        }}
      >
        {/* 	<DeleteButton
					mutationMode="pessimistic"
					style={{
						marginBottom: '16px',
						backgroundColor: 'red',
						color: 'white',
					}}
				/> */}
      </div>
      <TabbedShowLayout>
        <Tab label="Main">
          <ImageField source="imageUrl" label="Logo" fullWidth />
          <TextField source="id" />
          <UrlField source="domain" fullWidth />
          <TextField source="styleCss.cssName" fullWidth />
          <TextField source="askMe" fullWidth />
          <TextField source="tone" fullWidth />
          <TextField source="button" fullWidth />
          <TextField source="chatBotName" label="Name" fullWidth />
          <TextField source="expiryDate" label="Expiry Date" fullWidth />
          <TextField source="creationDate" label="Creation Date" fullWidth />
          <TextField source="footer" label="Footer" fullWidth />
          <BooleanField source="isActive" label="Is active" fullWidth />
          {/* <TextField source="openAiKey" fullWidth />
          <TextField source="reCaptchKey" fullWidth />
          <TextField source="reCaptchaSecret" fullWidth /> */}
          <TextField source="user.email" fullWidth />
        </Tab>
        <Tab label="Snippet">
          <ArrayField source="MlSnippet">
            <Pagination />
            <Datagrid bulkActionButtons={false}>
              <UrlField source="id" />
              <TextField source="prompt" />
              <TextField source="completion" />
            </Datagrid>
          </ArrayField>
        </Tab>

      <Tab label="Request">
          <ArrayField source="MLRequest">
            <Pagination />
            <Datagrid bulkActionButtons={false}>
              <UrlField source="id" />
              <DateField source="created" />
              <TextField source="prompt" />
              <TextField source="completion" />
              <TextField source="finish_reason" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </div>
  </Show>
);
