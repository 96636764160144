import {
	List,
	Datagrid,
	TextField,
	EmailField,
	Pagination,
	FilterLiveSearch,
	Filter,
	BooleanField,
	DateField,
	DateInput,
	TextInput,
	ReferenceInput,
	SelectInput,
	NumberField,
	NumberInput,
} from 'react-admin';

const UserFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="email" label="Email" />
		<TextInput source="username" label="User name" />
		<TextInput source="surname" label="Surname" />
		<NumberInput source="contenterPoints" label="Contenter Points" />
		<SelectInput
			source="role.id"
			label="Role"
			choices={[
				{ id: '1', name: 'Role 1' },
				{ id: '2', name: 'Role 2' },
			]}
		/>
		<DateInput source="registerDate" label="Filter day" />
	</Filter>
);

export const UserList = (props: any) => {
	return (
		<List
			sort={{ field: 'registerDate', order: 'DESC' }}
			{...props}
			resource="admin/users"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100, 150, 200]} />}
			filters={<UserFilter />}
		>
			<Datagrid rowClick="show" /* bulkActionButtons={false} */>
				<BooleanField source="isBlocked" sortable />
				<TextField source="id" sortable />
				<TextField source="role.id" sortable />
				<TextField source="username" sortable />
				<TextField source="surname" sortable />
				<EmailField source="email" sortable={false} />
				<NumberField source="contenterPoints" label="Contenter Points" />
				<NumberField source="snippetsCount" />
				<NumberField source="requestsCount" />
				<DateField source="registerDate" sortable showTime={true} />
				<DateField source="subscriptionDueDate" sortable showTime={false} />
				<DateField source="snippetsDeletionDate" sortable showTime={false} />
			</Datagrid>
		</List>
	);
};
