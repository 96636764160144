import {  Edit,   SimpleForm,  TextInput } from 'react-admin';

export const PromocodeEdit = () => (    
    <Edit mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput source="id"  disabled />
            <TextInput source="discount"  fullWidth />
          
  </SimpleForm>
    </Edit>
);