import {
	SimpleShowLayout,
	Show,
	TextField,
	DeleteButton,
	ArrayField,
	Datagrid,
} from 'react-admin';

export const ContentShow = () => (
	<Show>
		<DeleteButton
			mutationMode="pessimistic"
			style={{ margin: '16px', backgroundColor: 'red', color: 'white' }}
		/>
		<SimpleShowLayout>
			<TextField source="id" />
			<TextField source="keywords" fullWidth />
			<TextField source="textType" fullWidth />
			<TextField source="size" fullWidth />
			<TextField source="topic" fullWidth />
			<TextField source="text" fullWidth />
			<TextField source="user.email" label="Author" fullWidth />
			<ArrayField source="optionalTitles" label="Option Title">
				<Datagrid bulkActionButtons={false}>
					<TextField source="id" label="id" />
					<TextField source="title" />
					<TextField source="text" />
				</Datagrid>
			</ArrayField>
		</SimpleShowLayout>
	</Show>
);
