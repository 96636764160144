import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title } from 'react-admin';

const NotFoundPage = () => (
	<div
		style={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			height: '100vh',
		}}
	>
		<Card style={{ width: '400px' }}>
			<Title title="Not Found" />
			<CardContent>
				<h1 style={{ textAlign: 'center', fontSize: '30px', color: '#333' }}>
					404: Page not found
				</h1>
			</CardContent>
		</Card>
	</div>
);

export default NotFoundPage;
