import { BooleanInput, Edit, NumberInput, ReferenceField, SimpleForm, TextField, TextInput } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';



export const ProductEdit = () => (


    <Edit mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput source="id" disabled />
            <TextInput source="name" fullWidth label='Name' />
            <TextInput source="description" fullWidth label='Description' />
            <TextInput source="category" fullWidth label='Category' />
            <NumberInput source="productValue" fullWidth label='Product value' />
            <NumberInput source="priceInCentsUSD" fullWidth label='Price in cents USD' />
            <TextInput source="imageURL" fullWidth label='Image url' />
            <BooleanInput source="isBest" fullWidth label='Best' />
            <JsonInput source="bulletPoints" label='Points' />
            <JsonInput source="metadata" label='Metadata' />

        </SimpleForm>
    </Edit>
);