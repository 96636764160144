import React from 'react';
import {
  Datagrid,
  DateField,
  DateInput,
  Empty,
  ExportButton,
  Filter,
  List,
  Pagination,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin';
import MyImportButton from './MyImportButton';


const LeadsFilter = (props:any) => (
  <Filter {...props}>
    <TextInput source="email" label="Email" />
    <TextInput source="country" label="Country" />
    <TextInput source="companyURL" label="Company URL" />
    <DateInput source="registerDate" label="Filter day" />
  </Filter>
);

const ListActions = (props:any) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <LeadsFilter context="button" />
      <MyImportButton />
       <ExportButton
        {...props}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '10px 14px',
          borderRadius: '4px',
          background: '#2196F3',
          color: '#ffffff',
          fontSize: '14px',
          cursor: 'pointer',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      /> 
    </TopToolbar>
  );
};

export const LeadList = (props:any) => {
  return (
    <List
    	
      {...props}
      actions={<ListActions />}
      filters={<LeadsFilter />}
      resource="leads"
      empty={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            alignItems: 'center',
          }}
        >
          <div style={{ fontSize: '24px' }}>
            <Empty />
          </div>
          <MyImportButton />
        </div>
      }
      pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="fullName" />
        <TextField source="userSocial" />
        <TextField source="country" />
        <TextField source="companyURL" />
        <DateField source="registerDate" sortable showTime={true} />
        <TextField source="companysCountry" />
      </Datagrid>
    </List>
  );
};

