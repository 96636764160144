import {  Edit,   ReferenceInput,   SelectInput,   SimpleForm,  TextInput } from 'react-admin';

export const StyleEdit = () => (    
    <Edit mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput source="id"  disabled />
            <TextInput source="name" fullWidth label='Name'/>     
            <TextInput  source="cssName"fullWidth	label="Css Name"/>     
            <TextInput source="properties"fullWidth label='Properties' />
            <TextInput source='imageUrl' label='Image URL' fullWidth/>
            <ReferenceInput
					 source="structure" reference="admin/structure"  label="Structure name" >
					<SelectInput optionText="name" />
			</ReferenceInput>  
  </SimpleForm>
    </Edit>
);