import { ImageList } from '@mui/material';
import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';



export const PromocodeList = (props: any) => {
	return (
		<List 
		
			
			{...props}
			resource="admin/promocode"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show">
				<TextField source="id" />
				<TextField source="discount" />
				<TextField source="promoCode" />
				
			</Datagrid>
		</List>
	);
};

	
