import { BooleanInput, Edit, NumberInput, SimpleForm, TextInput } from 'react-admin';

export const UserEdit = () => (
	<Edit mutationMode="optimistic">
		<SimpleForm>
			<TextInput source="id" disabled />
			<TextInput source="email" fullWidth />
			<TextInput source="username" fullWidth />
			<TextInput source="surname" fullWidth />
			<BooleanInput source="isBlocked" fullWidth />
			<NumberInput source="contenterPoints" fullWidth />
			<TextInput source="subscriptionId" fullWidth />
			<TextInput source="subscriptionDueDate" fullWidth />
			<TextInput source="snippetsDeletionDate" fullWidth />
			<TextInput source="snippetsAvailable" fullWidth />
			<TextInput source="snippetsCount" fullWidth />
			<TextInput source="requestsPerMonth" fullWidth />
		</SimpleForm>
	</Edit>
);
