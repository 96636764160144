import { useEffect, useState } from 'react';
import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	SelectInput,
	required,
	BooleanInput,
	NumberInput,
} from 'react-admin';
import { useNotify, useRedirect } from 'react-admin';

export const ChatbotCreate = () => {
	const [perPage, setPerPage] = useState(10); 
  	const [usersCount, setUsersCount] = useState(0);
	const [selectedStructure, setSelectedStructure] = useState<any>(null);
	const [availableStyles, setAvailableStyles] = useState<any[]>([]);
	const notify = useNotify();
	const redirect = useRedirect();
	const HOST = process.env.REACT_APP_HOST;

	const handleStructureChange = async (structureId:any) => {
		try {
			const response = await fetch(HOST + `/admin/structure/${structureId}`, {
				headers: {
					'Content-Type': 'application/json',
					authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			});
			if (response.ok) {
				const structureData = await response.json();
				setSelectedStructure(structureData);
				setAvailableStyles(structureData.styleCss);
			}
		} catch (error) {
			console.error('Error fetching structure data:', error);
		}
	};


	useEffect(() => {
    const fetchUsersCount = async () => {
      try {
		const response = await fetch(HOST + '/admin/users/', {
          headers: {
            'Content-Type': 'application/json',
            authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        });
	
			if (response.ok) {
				const usersData = await response.json();       
				const count = usersData.total;
				setUsersCount(count);
				setPerPage(count);
			
			} else {
				console.error('Error fetching users data:', response);
			}
			} catch (error) {
			console.error('Error fetching users data:', error);
			}
		};

			fetchUsersCount();
		}, []);

	const handleSubmit = async (formData: any) => {
		const response = await fetch(HOST + '/admin/chatbots/create-bot', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(formData),
		});
		if (response.ok) {
			notify('ChatBot created successfully');
			redirect('/admin/chatbots');
		} else {
			const body = await response.json();
			throw new Error(`Failed to create chatbot: ${body}`);
		}
	};

	return (
		<Create title="Create Chatbot">
			<SimpleForm onSubmit={handleSubmit}>
				<TextInput
					source="domain"
					fullWidth
					validate={required()}
					label="Domain"
				/>
				<TextInput
					source="button"
					fullWidth
					validate={required()}
					label="Button"
				/>
				<TextInput
					source="askMe"
					fullWidth
					validate={required()}
					label="AskMe"
				/>
				<TextInput
					source="model"
					fullWidth
					label="Model"
				/>
				<NumberInput
					source="max"
					fullWidth
					label="Max page"
				/>
				<BooleanInput
					source="autocrawl"
					fullWidth
					validate={required()}
					label="Autocrawl"
				/>
				<TextInput
					source="tone"
					fullWidth
					validate={required()} 
					label="Tone"
				/>
				<ReferenceInput source="id" reference="admin/users" label="Users" perPage={perPage} >
					<SelectInput optionText="email" validate={required()}/>
				</ReferenceInput>
				
				<ReferenceInput source="structure" reference="admin/structure" label="Structure name">
					<SelectInput optionText="name" validate={required()}
					            onChange={event => handleStructureChange(event.target.value)} />
				</ReferenceInput>
				{selectedStructure && (
					<SelectInput
						source="styleCss" 
						choices={availableStyles.map(style => ({
							id: style.id,
							name: style.name,
						}))}
						optionText="name"
						label="Style CSS"
						validate={required()}
					/>
				)}
			</SimpleForm>
		</Create>
	);
};


			{/* <TextInput
					source="openAiKey"
					fullWidth
					validate={required()}
					label="openAiKey"
				/>
				<TextInput
					source="reCaptchKey"
					fullWidth
					validate={required()}
					label="reCaptchKey"
				/>
				<TextInput
					source="reCaptchaSecret"
					fullWidth
					validate={required()}
					label="reCaptchaSecret"
				/> */}
