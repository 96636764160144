import { ImageList } from '@mui/material';
import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';

const ScriptFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="name" label="Name" />
	
	</Filter>
);

export const ScriptList = (props: any) => {
	return (
		<List 
			sort={{ field: 'creationDate', order: 'DESC' }}
			filters={<ScriptFilter />}
			{...props}
			resource="admin/script"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" />
				<TextField source="name" />
			</Datagrid>
		</List>
	);
};

	
