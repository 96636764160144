import { useEffect, useState } from "react";
import { useListController } from "react-admin";
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";



const host = process.env.REACT_APP_HOST;
export function DateChart(props: any) {
    const [chartData, setChartData] = useState()

    const getChartData = (filterValues: any) => {

        const params = new URLSearchParams(filterValues)

        fetch(host + "/admin/chatbot/analytics/chart?" + params.toString())
            .then((data) => data.json())
            .then(data => data?.map((d: any) => {
                d.interval = new Date(d.interval).toDateString();
                d.count = Number(d.count)
                return d
            }))
            .then(json => setChartData(json))
            .catch()
    }

    const { filterValues } = useListController(props);

    useEffect(() => {
        console.log(filterValues);
        getChartData(filterValues)
    }, [filterValues])


    return (
        <>
            <LineChart width={window.innerWidth - 100} title='Requests and Page with mounted chatbot visits' height={window.innerHeight / 2}>
                <Line type="monotone" data={chartData} dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} label={"Page with chatbot visits"} />
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                <XAxis dataKey={"interval"} />
                <YAxis />
                <Legend />
                <Tooltip />
            </LineChart>
        </>
    )

}