import { CssBaseline, PaletteMode } from '@mui/material';
import { Admin, CustomRoutes, Resource, fetchUtils, AdminContext } from 'react-admin';
import { UserList } from './modules/user/user.list.components';
import { UserEdit } from './modules/user/user.edit.component';
import { UserCreate } from './modules/user/user.create.component';
import { ChatbotList } from './modules/chatbot/chatbot.list.components';
import { ChatbotCreate } from './modules/chatbot/chatbot.create.components';
import crudProvider from 'ra-data-nestjsx-crud';
import { UserShow } from './modules/user/user.show.component';
import { ChatBotShow } from './modules/chatbot/chatbot.show.component';
import { authProvider } from './modules/auth/authProvider';
import UserIcon from '@mui/icons-material/Group';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ChatIcon from '@mui/icons-material/Chat';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { MyLoginPage } from './modules/auth/MyLoginPag';
import { TextList } from './modules/content/content.list.component';
import { ContentShow } from './modules/content/content.show.component';
import { LogList } from './modules/error/log.list.component';
import { LogShow } from './modules/error/log.show.component';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import EmailIcon from '@mui/icons-material/Email'
import FeedbackIcon from '@mui/icons-material/Feedback';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import HistoryIcon from '@mui/icons-material/History';
import StyleIcon from '@mui/icons-material/Style';
import JavascriptIcon from '@mui/icons-material/Javascript';
import { defaultTheme } from 'react-admin';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { ConfigList } from './modules/config/config.list.components';
import { FeedbackList } from './modules/feedback/feedback.list.component';
import { FeedbackShow } from './modules/feedback/feedback.show.component';
import NotFound from './modules/notFound/NotFound';
import { ProductList } from './modules/product/product.list.components';
import { ProductShow } from './modules/product/product.show.component';
import { ProductEdit } from './modules/product/product.edit.components';
import { ProductCreate } from './modules/product/product.create.components';
import { ChatbotEdit } from './modules/chatbot/chatbot.edit.components';
import AddCardIcon from '@mui/icons-material/AddCard';
import PaymentList from './modules/payment/payment.list.components';
import { PaymentShow } from './modules/payment/payment.show.component';
import { LeadList } from './modules/lead/lead.list.component';
import MediationIcon from '@mui/icons-material/Mediation';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import { ChatbotHistoryList } from './modules/chatbotRequestHistory/chatbotHistory.list.component';
import { ChatbotHistoryShow } from './modules/chatbotRequestHistory/chatbotHistory.show.component';
import { LeadShow } from './modules/lead/lead.show.component ';
import { StyleList } from './modules/style/style.list.component';
import { StyleCreate } from './modules/style/style.create.components';
import { StyleShow } from './modules/style/style.show.component';
import { StyleEdit } from './modules/style/style.edit.component';
import { ScriptList } from './modules/script/script.list.component';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import { ScriptCreate } from './modules/script/script.create.components';
import { ScriptShow } from './modules/script/script.show.component';
import { ScriptEdit } from './modules/script/script.edit.component';
import { StructureList } from './modules/structure/structure.list.component';
import { StructureCreate } from './modules/structure/structure.create.components';
import { StructureShow } from './modules/structure/structure.show.component';
import { StructureEdit } from './modules/structure/structure.edit.component';
import { FondyList } from './modules/paymentFondy/fondy.list.components';
import TerrainIcon from '@mui/icons-material/Terrain';
import { FondyShow } from './modules/paymentFondy/fondy.show.component';
import { CreateEmailTemplate } from './modules/emailTemplate/emailTemplate.create';
import { ListEmailTemplate } from './modules/emailTemplate/emailTemplate.list';
import { ShowEmailTemplate } from './modules/emailTemplate/emailTemplate.show';
import { EditEmailTemplate } from './modules/emailTemplate/emailTemplate.edit';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { AnalyticsList } from './modules/analytics/analytics.list.component';
import { TrackingList } from './modules/tracking/tracking.list.component';
import { TrackingShow } from './modules/tracking/tracking.show.component';
import { TrackingEdit } from './modules/tracking/tracking.edit.component';
import { PromocodeCreate } from './modules/promocode/promocode.create.component';
import { PromocodeList } from './modules/promocode/promocode.list.component';
import { PromocodeEdit } from './modules/promocode/promocode.edit.component';
import { PromocodeShow } from './modules/promocode/promocode.show.component';

const HOST = process.env.REACT_APP_HOST;
const PAY = process.env.REACT_APP_HOST2;

const dataProvider = crudProvider(
	HOST || '',
	async (url: string, options: any = {}) => {
		const headers = {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		};

		if (!options.headers) {
			options.headers = new Headers(headers);
		} else {
			options.headers = new Headers({
				...headers,
				...options.headers,
			});
		}

		/* 	if (url.includes('payment')) {
				const paymentUrl = url.replace(HOST ?? '', '');
				url = PAY + paymentUrl;
			} */

		return await fetchUtils.fetchJson(url, options);
	}
);





function App() {
	return (
		<>
			<CssBaseline />
			<Admin
				//theme={theme}
				//	darkTheme={darkTheme}
				dataProvider={dataProvider}
				authProvider={authProvider}
				loginPage={MyLoginPage}
				catchAll={NotFound}
			>
				<Resource
					name="admin/chatbot/analytics"
					list={AnalyticsList}
					options={{ label: 'Analytics' }}
					icon={AnalyticsIcon}
				/>
				<Resource
					name="admin/users"
					list={UserList}
					show={UserShow}
					edit={UserEdit}
					create={UserCreate}
					options={{ label: 'Users' }}
					icon={UserIcon}
				/>
				<Resource
					name="admin/product"
					list={ProductList}
					show={ProductShow}
					edit={ProductEdit}
					create={ProductCreate}
					options={{ label: 'Product' }}
					icon={ProductionQuantityLimitsIcon}
				/>
				<Resource
					name="admin/chatbots"
					show={ChatBotShow}
					list={ChatbotList}
					edit={ChatbotEdit}
					create={ChatbotCreate}
					options={{ label: 'Chatbot' }}
					icon={ChatIcon}
				/>
				<Resource
					name="admin/contents"
					list={TextList}
					show={ContentShow}
					options={{ label: 'Contents' }}
					icon={AssignmentIcon}
				/>
				<Resource
					name="admin/feedback"
					list={FeedbackList}
					show={FeedbackShow}
					options={{ label: 'Feedback' }}
					icon={FeedbackIcon}
				/>
				<Resource
					name="admin/log"
					list={LogList}
					show={LogShow}
					options={{ label: 'Logs Message' }}
					icon={LogoDevIcon}
				/>

				<Resource
					name="admin/config"
					icon={WysiwygIcon}
					options={{ label: 'Config' }}
					list={ConfigList}
				/>
				<Resource
					name="leads"
					icon={AccessibilityNewIcon}
					options={{ label: 'Leads' }}
					list={LeadList}
					show={LeadShow}
				/>
				<Resource
					name="stripe/payment"
					icon={AddCardIcon}
					options={{ label: 'Payment' }}
					list={PaymentList}
					show={PaymentShow}
				/>
				<Resource
					name="payment"
					icon={TerrainIcon}
					options={{ label: 'Fondy Payment' }}
					list={FondyList}
					show={FondyShow}
				/>
				<Resource
					name="admin/style"
					icon={StyleIcon}
					options={{ label: 'Styles' }}
					list={StyleList}
					create={StyleCreate}
					show={StyleShow}
					edit={StyleEdit}
				/>
				<Resource
					name="admin/script"
					icon={JavascriptIcon}
					options={{ label: 'Script' }}
					list={ScriptList}
					create={ScriptCreate}
					show={ScriptShow}
					edit={ScriptEdit}
				/>
				<Resource
					name="admin/trackings"
					icon={SpatialTrackingIcon}
					options={{ label: 'Tracking' }}
					list={TrackingList}
					show={TrackingShow}
					edit={TrackingEdit}
				/>
				<Resource
					name="admin/promocode"
					icon={QrCodeScannerIcon}
					options={{ label: 'Promocode' }}
					list={PromocodeList}
					edit={PromocodeEdit}
					show={PromocodeShow}
					create={PromocodeCreate}

				/>
				<Resource
					name="admin/structure"
					icon={MediationIcon}
					options={{ label: 'Structure' }}
					list={StructureList}
					create={StructureCreate}
					show={StructureShow}
					edit={StructureEdit}
				/>

				<Resource
					name="admin/chatbots/requests/history"
					icon={HistoryIcon}
					options={{ label: 'Chatbot History' }}
					list={ChatbotHistoryList}
					show={ChatbotHistoryShow}
				/>
				<Resource
					name="email/template"
					icon={EmailIcon}
					options={{ label: 'Email Templates' }}
					create={CreateEmailTemplate}
					list={ListEmailTemplate}
					show={ShowEmailTemplate}
					edit={EditEmailTemplate}
				/>

			</Admin>
		</>
	);
}

export default App;
