import { Autocomplete, Box, Container, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';

const PaymentList = () => {
  const [lastPaymentId, setLastPaymentId] = useState<string | null>(null);
  const [firstPaymentId, setFirstPaymentId] = useState<string | null>(null);
  const [limit, setLimit] = useState<number>(10);
  const [data, setData] = useState<any>({});
  const [totalPayments, setTotalPayments] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [userIdQuery, setUserIdQuery] = useState<string>("");
  const [statusQuery, setStatusQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>('');
  const [customerFound, setCustomerFound] = useState(true);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const isNextPageDisabled = count <= 0 || searchResults.length < limit;

  const PAY = process.env.REACT_APP_HOST2;

  function formatDate(timestamp: any) {
    const date = new Date(timestamp);
    const formattedDate = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    return formattedDate;
  }

  function objectToSearchParams(obj: any) {
    const params = new URLSearchParams();
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
        params.append(key, obj[key]);
      }
    }
    return params;
  }

  useEffect(() => {
    const queryParams = objectToSearchParams({
      ...(lastPaymentId && { starting_after: lastPaymentId }),
      ...(firstPaymentId && { ending_before: firstPaymentId }),
      ...(limit && { limit: String(limit) }),
      ...(userIdQuery && { userId: userIdQuery }),
      ...(statusQuery && { status: statusQuery }),
      ...(startDate && { start_date: new Date(startDate).getTime() / 1000 }),
      ...(endDate && { end_date: new Date(endDate).getTime() / 1000 }),
    });

    try {
      const response = fetch(`${PAY}/stripe/payment?${queryParams}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          setData(res);
          setTotalPayments(res.total);
          setSearchResults(res.data);
          setCustomerFound(res.data.length > 0);
        });
    } catch (error) {
      setCustomerFound(false);
    }
  }, [limit, lastPaymentId, firstPaymentId, userIdQuery, statusQuery, startDate, endDate]);

  const toggleSortOrder = (column: string) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const filteredData = data?.data?.filter((payment: any) => {
    const userIdIncludes =
      payment.metadata.userId &&
      typeof payment.metadata.userId === 'string' &&
      payment.metadata.userId.toLowerCase().includes(userIdQuery.toLowerCase());

    const statusIncludes =
      typeof payment.status === 'string' &&
      payment.status.toLowerCase().includes(statusQuery.toLowerCase());
    const startDateFilter =
      !startDate || new Date(payment.created * 1000) >= new Date(startDate);
    const endDateFilter =
      !endDate || new Date(payment.created * 1000) <= new Date(endDate);

    return (
      (!userIdQuery || userIdIncludes) &&
      (!statusQuery || statusIncludes) &&
      startDateFilter &&
      endDateFilter
    );
  });

  const uniqueEmailsSet = new Set(searchResults.map((payment: any) => payment.customer.email));
  const uniqueEmails = Array.from(uniqueEmailsSet);

  const sortedData = React.useMemo(() => {
    if (sortColumn === 'ID') {
      return filteredData.sort((a: any, b: any) => (sortOrder === 'asc' ? a.id - b.id : b.id - a.id));
    } else if (sortColumn === 'Amount') {
      return filteredData.sort((a: any, b: any) => (sortOrder === 'asc' ? a.amount - b.amount : b.amount - a.amount));
    } else if (sortColumn === 'Created') {
      return filteredData.sort((a: any, b: any) => (sortOrder === 'asc' ? a.created - b.created : b.created - a.created));
    } else if (sortColumn === 'Status') {
      return filteredData.sort((a: any, b: any) => (sortOrder === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status)));
    } else if (sortColumn === 'UserId') {
      return filteredData.sort((a: any, b: any) => {
        const userIdA = parseInt(a.metadata.userId, 10) || 0;
        const userIdB = parseInt(b.metadata.userId, 10) || 0;
        return sortOrder === 'asc' ? userIdA - userIdB : userIdB - userIdA;
      });
    } else {
      return filteredData;
    }
  }, [sortColumn, sortOrder, filteredData]);

  return (
    <Container
      maxWidth="xl"
      sx={{
        paddingTop: '20px',
        marginBottom: '20px',
        maxWidth: '100%',
      }}
    >
      <Box mt={2}>
        <Typography variant="h5">Payment List</Typography>
      </Box>
      <Box mt={2}>
        <Autocomplete
  options={uniqueEmails.map((email: string) => ({
    label: email,
    value: searchResults.find((payment: any) => payment.customer.email === email)?.metadata.userId || '',
  }))}
  value={userIdQuery}
  onChange={(_, newValue) => setUserIdQuery(newValue ? newValue.value : '')}
  getOptionLabel={(option: any) => option.label || ''}
  renderInput={(params) => (
    <TextField
      {...params}
      variant="outlined"
      label="Search by Customer..."
      margin="dense"
      fullWidth
    />
  )}
/>


        <TextField
          variant="outlined"
          label="Search by Status..."
          value={statusQuery}
          onChange={(e) => setStatusQuery(e.target.value)}
          fullWidth
          margin="dense"
        />
        <Box mt={2}>
          <div>
            <Typography variant="subtitle1">Start Date:</Typography>
            <TextField
              variant="outlined"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              margin="dense"
            />
          </div>
          <div>
            <Typography variant="subtitle1">End Date:</Typography>
            <TextField
              variant="outlined"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              margin="dense"
            />
          </div>
        </Box>
      </Box>

      <Box mt={2}>
        <Paper>
          <List>
            <ListItem style={{ fontWeight: 'bold', display: "flex", marginBottom: "20px", justifyContent: "space-around" }}>
              <div onClick={() => toggleSortOrder('id')}>ID</div>
              <div onClick={() => toggleSortOrder('Amount')} style={{ marginLeft: "60px", cursor: "pointer" }}>Amount</div>
              <div onClick={() => toggleSortOrder('Created')} style={{ cursor: "pointer" }}>Created</div>
              <div onClick={() => toggleSortOrder('Status')} style={{ cursor: "pointer" }}>Status</div>
              <div style={{ marginLeft: "40px" }}>Customer</div>
              <div onClick={() => toggleSortOrder('UserId')} style={{ marginRight: "60px", cursor: "pointer" }}>User id</div>
            </ListItem>

            {customerFound ? (
              sortedData?.map((payment: any) => (
            <ListItem key={payment.id} button component="a" href={`#/stripe/payment/${payment.id}/show`} style={{ display: "flex" }}>
              <ListItemText primary={payment.id } style={{ width: "100px", justifyContent: "center",marginRight:'60px' }} />
              <ListItemText primary={payment.amount } style={{ width: "100px", justifyContent: "center",marginLeft: "40px" }} />
              <ListItemText primary={formatDate(payment.created * 1000) } style={{ width: "100px", justifyContent: "center" }} />
              <ListItemText primary={payment.status } style={{ width: "100px", justifyContent: "center" }} />
              <ListItemText primary={payment.customer ? payment.customer.email:"" } style={{ width: "100px", justifyContent: "center" , marginRight: "80px"}} />
              <ListItemText primary={payment.metadata ? payment.metadata.userId:'' } style={{  width: "60px" }} />
            </ListItem>

              ))
            ) : (
              <ListItem>
                <ListItemText primary="Customer not found" />
              </ListItem>
            )}
          </List>
        </Paper>
      </Box>

      <Box display="flex" justifyContent="space-between" mt={2}>
        <Typography variant="subtitle2" fontWeight="bold">
          Currently showing payments {count + 1} to {count + searchResults.length} of {totalPayments}
        </Typography>
        <Box display="flex">
          <Button variant="contained" onClick={() => setLimit(10)} sx={{ ml: 2 }}>
            10 per page
          </Button>
          <Button variant="contained" onClick={() => setLimit(50)} sx={{ ml: 2 }}>
            50 per page
          </Button>
          <Button variant="contained" onClick={() => setLimit(100)} sx={{ ml: 2 }}>
            100 per page
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (count <= 0) return;
              setFirstPaymentId(searchResults[0]?.id);
              setLastPaymentId(null);
              setCount((count) => count - limit);
            }}
            sx={{ ml: 2 }}
          >
            Back page
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              if (count >= totalPayments) return;
              setLastPaymentId(searchResults[searchResults.length - 1]?.id);
              setFirstPaymentId(null);
              setCount((count) => count + limit);
            }}
            sx={{ ml: 2 }}
          >
            Next page
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PaymentList;
