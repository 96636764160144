import React from 'react';
import {
	SimpleShowLayout,
	Show,
	TextField,
	useShowController,
	Button,
	TopToolbar,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import FondyRefundPaymentButton from './fondyRefaundPaymentButton';

const ListActions = (props: any) => {
	const { record } = useShowController(props);
	const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	};
	const BackButton = () => (
		<div>
			<Button
				label="Back"
				variant="contained"
				color="primary"
				onClick={goBack}
			/>
		</div>
	);
 const isOrderReversed = record.order_status === "reversed";
	return (
		<TopToolbar>
			<React.Fragment>
				    {!isOrderReversed && <FondyRefundPaymentButton data={record} />}
			</React.Fragment>
 
			<BackButton />
		</TopToolbar>
	);
};

export const FondyShow = () => (
	<Show resource="payment" actions={<ListActions />}>
		<SimpleShowLayout>
			<TextField source="id" label="ID" />
			<TextField source="order_id" label="Order ID" />
			<TextField source="order_time" label="Order Time" />
			<TextField source="payment_id" label="Payment ID" />
			<TextField source="createdAt" label="Created At" />
			<TextField source="masked_card" label="Masked Card" />
			<TextField source="card_type" label="Card Type" />
			<TextField source="amount" label="Amount" />
			<TextField source="order_status" label="Status" />
			<TextField source="actual_amount" label="Actual Amount" />
			<TextField source="currency" label="Currency" />
			<TextField source="actual_currency" label="Actual Currency" />
			<TextField source="merchant_data" label="Merchant Data" />
			<TextField source="user.username" label="Username" />
			<TextField source="user.surname" label="Surname" />
			<TextField source="user.email" label="Email" />
			<TextField source="user.registerDate" label="Register Date" />
			<TextField
				source="user.subscriptionDueDate"
				label="Subscription Due Date"
			/>
			<TextField source="user.requestsCount" label="Requests Count" />
			<TextField source="user.snippetsCount" label="Snippets Count" />
			<TextField source="user.isBlocked" label="Is Blocked" />
			<TextField source="user.isEmailConfirmed" label="Is Email Confirmed" />
			<TextField source="user.contenterPoints" label="Content Points" />
			<TextField source="user.authProvider" label="Auth Provider" />
		</SimpleShowLayout>
	</Show>
);
