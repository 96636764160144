import {
	SimpleShowLayout,
	Show,
	TextField,
    NumberField,
	ImageField,
	ArrayField,
	Datagrid,
} from 'react-admin';

export const StructureShow = () => (
	<Show>
		<SimpleShowLayout>
			<NumberField source="id" />
			<TextField source="name" fullWidth />
			 <ImageField source="imageUrl" label="Logo" fullWidth />			
			<ArrayField source="styleCss">
				<Datagrid bulkActionButtons={false}>            
					<TextField source="name" />
					<TextField source="id" />
					 <ImageField source="imageUrl" label="Logo" fullWidth />
				</Datagrid>
       		</ArrayField>
     </SimpleShowLayout>
    </Show>
)