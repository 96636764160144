import React, { useState } from 'react';
import {
	FormControlLabel,
	Checkbox,
	TextField,
	Button,
	Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { authProvider } from './authProvider';
import { useRedirect } from 'react-admin';

const CenteredContainer = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

const LoginBox = styled(Box)({
	maxWidth: '400px',
	margin: '0 auto',
	padding: '16px',
	border: '1px solid #ccc',
	borderRadius: '4px',
});

export const MyLoginPage = () => {
	const [rememberMe, setRememberMe] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const redirect = useRedirect();
	const login = authProvider.login;
	const checkAuth = authProvider.checkAuth;

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		try {
			await login({ email, password, rememberMe });
			await checkAuth();
			redirect('/admin/users');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<CenteredContainer>
			<LoginBox>
				<form onSubmit={handleSubmit}>
					<TextField
						label="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<TextField
						label="Password"
						type="password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={rememberMe}
								onChange={(e) => setRememberMe(e.target.checked)}
								color="primary"
							/>
						}
						label="Remember me"
					/>
					<Button type="submit" variant="contained" color="primary" fullWidth>
						Login
					</Button>
				</form>
			</LoginBox>
		</CenteredContainer>
	);
};
