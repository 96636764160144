import {
    SimpleShowLayout,
    Show,
    TextField,
    UrlField,
    ImageField,
} from 'react-admin';

export const ChatbotHistoryShow = () => (
    <Show>
        <div style={{ position: 'relative' }}>
            <div
                style={{
                    margin: '16px',

                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                }}
            >
            </div>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="cached" />
                <TextField source="completion" />
                <TextField source="created" />
                <TextField source="finish_reason" />
                <TextField source="session" />
                <TextField source="type" />
                <TextField source="prompt" />
            </SimpleShowLayout>
        </div>
    </Show>
);
