import {
	SimpleShowLayout,
	Show,
	TextField,
    NumberField,
	ImageField,
} from 'react-admin';

export const PromocodeShow = () => (
	<Show>
		<SimpleShowLayout>
			<NumberField source="id" />
			<TextField source="discount" fullWidth/>
			<TextField source="promoCode" fullWidth/>
			
			
     </SimpleShowLayout>
    </Show>
)