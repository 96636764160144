import React, { useCallback } from 'react';
import {  useRefresh } from 'react-admin';
import FileUploadIcon from '@mui/icons-material/FileUpload';


const MyImportButton = (props) => {
  const refresh = useRefresh();
  const HOST = process.env.REACT_APP_HOST;

  const handleFileChange = useCallback(
    (event) => {
      const file = event.target.files[0];
      if (file) {

     const allowedFileTypes = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
     console.log(allowedFileTypes)
       if (!allowedFileTypes.includes(file.type)) {
            alert('Please select a CSV or XLSX file.');
            return;
        }
        const formData = new FormData();
        console.log(formData)
        formData.append('file', file);
        
        fetch(`${HOST}/leads/import`, {
          method: 'POST',
          body: formData,
        })
          .then((response) => response.json())
          .then(() => {
            refresh(); 
            
          })
          .catch((error) => {
            console.log(error)
          });
      }
    },
    [refresh]
  );

 return (
    <>
      <label
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px 12px',
          borderRadius: '4px',
          background: '#2196F3',
          color: '#ffffff',
          cursor: 'pointer',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        <FileUploadIcon style={{ marginRight: '8px' }} />
        IMPORT
        <input
          type="file"
          onChange={handleFileChange}
          style={{
            display: 'none',
          }}
        />
      </label>
    </>
  );
};

export default MyImportButton;
