import {
	SimpleShowLayout,
	Show,
	TextField,
    NumberField,
	ImageField,
} from 'react-admin';

export const ScriptShow = () => (
	<Show>
		<SimpleShowLayout>
			<NumberField source="id" />
			<TextField source="name" fullWidth />
			<TextField source="code" fullWidth />
     </SimpleShowLayout>
    </Show>
)