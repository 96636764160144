import { ImageList } from '@mui/material';
import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	DeleteButton,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';

const FondyFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="user.email" label="User email" />
		<TextInput source="order_id" label="Order Id" />
		<TextInput source="order_status" label="Status" />
		<DateInput source="createdAt" label="Create Date" />
	</Filter>
);

export const FondyList = (props: any) => {
	return (
		<List
			sort={{ field: 'createdAt', order: 'DESC' }}
			filters={<FondyFilter />}
			{...props}
			resource="payment"
			//pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" >
				<TextField source="id" />
				<TextField source="order_id" />
				<TextField source="user.email" />
				<TextField source="order_status" />
				<DateField source="createdAt" sortable showTime={true}  />
			
			</Datagrid>
		</List>
	);
};
