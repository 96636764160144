import { ImageList } from '@mui/material';
import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';

const ChatBotFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="tone" label="Tone" />
		<TextInput source="domain" label="Domain" />
		<TextInput source="style" label="Style" />
		<TextInput source="user.email" label="User email" />
		<DateInput source="creationDate" label="Create Date" />
	</Filter>
);

export const ChatbotList = (props: any) => {
	return (
		<List
			sort={{ field: 'creationDate', order: 'DESC' }}
			filters={<ChatBotFilter />}
			{...props}
			resource="admin/chatbots"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<BooleanField source="isActive" />
				<ImageField source='imageUrl'  label='Icon'
				sx={{ '& img': { maxWidth: 70, maxHeight: 70, objectFit: 'contain' } }}
				/>
				<TextField source="id" />
				<UrlField source="domain" />
				<TextField source="tone" />
				 <TextField source="styleCss.cssName"  label="Style" />
				<TextField source="button" />
				<TextField source="askMe" />
				{/* <TextField source="openAiKey" /> */}
				<DateField source="creationDate" label='Creation date' sortable showTime={true} />
				<DateField source="expiryDate" label='Expiry date' sortable showTime={true} />
				<TextField source="user.email" label='User email' />
			</Datagrid>
		</List>
	);
};
