import { ImageList } from '@mui/material';
import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';

const TrackingFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="username" label="Username" />
		<TextInput source="email" label="Email" />
		<DateInput source="createdAt" label="Create Date" />
	
	</Filter>
);

export const TrackingList = (props: any) => {
	return (
		<List 
			sort={{ field: 'createdAt', order: 'DESC' }}
			filters={<TrackingFilter />}
			{...props}
			resource="admin/trackings"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show">
				<TextField source="id" />
				<TextField source="opened" />
				<TextField source="email" />
				<TextField source="username" />
				<TextField source="createdAt" />

			</Datagrid>
		</List>
	);
};

	
