import {
	SimpleShowLayout,
	Show,
	TextField,
    NumberField,
	ImageField,
} from 'react-admin';

export const StyleShow = () => (
	<Show>
		<SimpleShowLayout>
			<NumberField source="id" />
			<ImageField source='imageUrl'  label='Icon'
				sx={{ '& img': { maxWidth: 200 , maxHeight: 200 , objectFit: 'contain' } }}
			/>		
			<TextField source="name" fullWidth />
			<TextField source="structure.name" fullWidth />
			<TextField source="cssName"	fullWidth	label="Css Name"/>
			<TextField source="properties" fullWidth />
     </SimpleShowLayout>
    </Show>
)