import {
	SimpleShowLayout,
	Show,
	TextField,
	DeleteButton,
	DateField,
	BooleanField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const LogShow = () => (
	<Show>
		<SimpleShowLayout>
			<TextField source="id" />
			<BooleanField source="success" fullWidth />
			<TextField source="httpStatus" fullWidth />
			<TextField source="method" fullWidth />
			<TextField source="url" fullWidth />
			<TextField source="ip" fullWidth />
			<TextField source="origin" fullWidth />
			<TextField source="reqDateTime" fullWidth />
			<TextField source="executionTime" fullWidth />
			<TextField source="role" fullWidth />

			<JsonField source="user" jsonString={true} />
			<JsonField source="headers" jsonString={true} />
			<JsonField source="body" jsonString={true} />
			<JsonField source="resBody" jsonString={true} />
			<JsonField source="params" jsonString={true} />
			<JsonField source="query" jsonString={true} />
		</SimpleShowLayout>
	</Show>
);
