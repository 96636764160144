import {
	SimpleShowLayout,
	Show,
	TextField,
	UrlField,
	DeleteButton,
	FileField,
	ImageField,
	BooleanField,
	NumberField,
	DateField,
	ArrayField,
	Datagrid,
	Pagination,
	Button,
	TopToolbar,
  ExportButton,
  useShowController,
} from 'react-admin';
import { useNavigate } from 'react-router-dom';
import RefundPaymentButton from './RefundPaymentButton';
import React from 'react';

const ListActions = (props:any) => { 
const { record } = useShowController(props);
const navigate = useNavigate();

const goBack = () => {
  navigate(-1);
};
const BackButton = () => (
<div>
  <Button label="Back" variant="contained"
      color="primary" onClick={goBack} />
</div>
);
  const hasMetadata = record && record.metadata && Object.keys(record.metadata).length > 0;
  return (
    <TopToolbar>
      {hasMetadata && (
        <React.Fragment>
          <RefundPaymentButton id={record.id} />
        </React.Fragment>
      )}   
      <BackButton />      
    </TopToolbar>
  );
};

export const PaymentShow = (props:any) => {
 
 const id = window.location.href.split('/').slice(-2, -1)[0];

  return (
    <Show {...props}
    actions={<ListActions id={id} />}>
    <SimpleShowLayout>      
      <TextField source="id" />
      <TextField source="amount" />
      <TextField source="amount_capturable" />
      <TextField source="amount_details.tip" />
      <TextField source="amount_received" />
      <TextField source="application" />
      <TextField source="application_fee_amount" />
      <TextField source="automatic_payment_methods" />
      <TextField source="canceled_at" />
      <TextField source="cancellation_reason" />
      <TextField source="capture_method" />
      <TextField source="client_secret" />
      <TextField source="confirmation_method" />
      <TextField source="created" />
      <TextField source="currency" />
      <TextField source="customer" />    
      <TextField source="description" />
      <TextField source="invoice" />
      <TextField source="last_payment_error" />
      <TextField source="latest_charge" />
      <TextField source="livemode" />
      <TextField source="metadata.isPurchaseRetrieved" />
      <TextField source="metadata.productId" />
      <TextField source="metadata.totpcode" />
      <TextField source="metadata.userId" />
      <TextField source="next_action" />
      <TextField source="object" />
      <TextField source="on_behalf_of" />
      <TextField source="payment_method" />
      <TextField source="payment_method_options" />
      <TextField source="payment_method_options.card.installments" />
      <TextField source="payment_method_options.card.mandate_options" />
      <TextField source="payment_method_options.card.network" />
      <TextField source="payment_method_options.card.request_three_d_secure" />
      <TextField source="payment_method_types" />
      <TextField source="processing" />
      <TextField source="receipt_email" />
      <TextField source="review" />
      <TextField source="setup_future_usage" />
      <TextField source="shipping" />
      <TextField source="source" />
      <TextField source="statement_descriptor" />
      <TextField source="statement_descriptor_suffix" />
      <TextField source="status" />
      <TextField source="transfer_data" />
      <TextField source="transfer_group" />
    </SimpleShowLayout>
  </Show>
  );
};
