import {
	Datagrid,
	Filter,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
} from 'react-admin';

const StructureFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="name" label="Name" />	
	</Filter>
);

export const StructureList = (props: any) => {
	return (
		<List 
			sort={{ field: 'creationDate', order: 'DESC' }}
			filters={<StructureFilter />}
			{...props}
			resource="admin/structure"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" />
				<TextField source="name"/>
				<ImageField source="imageUrl" label="Logo" fullWidth />
			</Datagrid>
		</List>
	);
};

	
