import {
	SimpleShowLayout,
	Show,
	TextField,
    NumberField,
	ImageField,
} from 'react-admin';

export const TrackingShow = () => (
	<Show>
		<SimpleShowLayout>
			<NumberField source="id" />
			<TextField source="opened" />
			<TextField source="email" />
			<TextField source="username" />
			<TextField source="createdAt" />
     </SimpleShowLayout>
    </Show>
)