import {
	Datagrid,
	ExportButton,	
	List,
	Pagination,
	Show,
	SimpleShowLayout,
	TextField,
	TopToolbar,
	
} from 'react-admin';
import MyImportButton from './MyImportButton';





export const LeadShow = (props: any) => {
	return (        
		<Show
			{...props}      
			resource="leads"
		>
		<SimpleShowLayout>
	<TextField source="id" />
  <TextField source="email"  />
  <TextField source="emailStatus" />
  <TextField source="firstName" />
  <TextField source="lastName" />
  <TextField source="fullName" />
  <TextField source="userSocial" />
  <TextField source="jobPosition" />
  <TextField source="country" />
  <TextField source="location" />
  <TextField source="industry" />
  <TextField source="addDate" />
  <TextField source="companyName" />
  <TextField source="companyURL" />
  <TextField source="companySocial" />
  <TextField source="companySize" />
  <TextField source="companysCountry" />
  <TextField source="companyLocation" />
  <TextField source="state" />
  <TextField source="city" />
  <TextField source="companyIndustry" />
  <TextField source="hQPhone" />
  <TextField source="scriptUrl" />
  {/* Assuming the relation is one-to-many */}
  <TextField source="chatbot.id" />			
			</SimpleShowLayout>
		</Show>
	);
};
