import { Datagrid, Filter, List, Pagination, TextField, TextInput } from 'react-admin';

const ChatBotHistoryFilter = (props: any) => (
    <Filter {...props}>
        <TextInput source="type" label="Type" />
        <TextInput source="prompt" label="Prompt" />
        <TextInput source="completion" label="Completion" />
    </Filter>
);

export const ChatbotHistoryList = (props: any) => {
    return (
        <List
            filters={<ChatBotHistoryFilter />}
            {...props}
            resource="admin/chatbots/requests/history"
            pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
        >
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="cached" />
                <TextField source="completion" />
                <TextField source="created" />
                <TextField source="finish_reason" />
                <TextField source="session" />
                <TextField source="type" />
                <TextField source="prompt" />
            </Datagrid>
        </List>
    );
};
