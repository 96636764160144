import {
	List,
	Datagrid,
	TextField,
	Filter,
	FilterLiveSearch,
	Pagination,
	DateField,
	DateInput,
	TextInput,
} from 'react-admin';

const FeedbackFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="name" label="Name" />
		<TextInput source="email" label="User Email" />
		<TextInput source="subject" label="Subject" />
		<TextInput source="message" label="Message" />
		<DateInput source="creationDate" label="Create Date" />
	</Filter>
);
export const FeedbackList = (props: any) => {
	return (
		<List
			{...props}
			sort={{ field: 'creationDate', order: 'DESC' }}
			resource="admin/feedback"
			filters={<FeedbackFilter />}
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" />
				<DateField source="creationDate" sortable showTime={true} />
				<TextField source="name" />
				<TextField source="email" />
				<TextField source="subject" />
				<TextField source="message" />
			</Datagrid>
		</List>
	);
};
