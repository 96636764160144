import {  Edit,   SimpleForm,  TextInput } from 'react-admin';

export const StructureEdit = () => (    
    <Edit mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput source="id"  disabled />
            <TextInput source="name" fullWidth label='Name'/>  
            <TextInput source="imageUrl" fullWidth label='Logo'/>  
            
   </SimpleForm>
    </Edit>
);