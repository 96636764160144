import { NumberField, RichTextField, Show, SimpleShowLayout, TextField, useShowController } from "react-admin";


export function ShowEmailTemplate(props: any) {

    return (
        <>
            <Show>
                <SimpleShowLayout>
                    <NumberField source="id" />
                    <TextField source="name" fullWidth />
                    <TextField source="subject" fullWidth />
                    <RichTextField source="template" fullWidth />
                </SimpleShowLayout>
            </Show>
        </>
    )

}

