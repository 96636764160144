import { arrayBuffer } from 'node:stream/consumers';
import { format } from 'path';
import {
	Create,
	SimpleForm,
	TextInput,
	ReferenceInput,
	SelectInput,
	required,
	NumberInput,
	BooleanInput,
	ArrayInput,
	SimpleFormIterator,
	ArrayField,
} from 'react-admin';
import { useNotify, useRedirect } from 'react-admin';
import { JsonInput } from 'react-admin-json-view';
import { isObject } from 'util';





export const ProductCreate = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const HOST = process.env.REACT_APP_HOST;

	const handleSubmit = async (formData: any) => {
		const response = await fetch(HOST + '/admin/product', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(formData),
		});
		if (response.ok) {
			notify('Product created successfully');
			redirect('/admin/product');
		} else {
			const body = await response.json();
			throw new Error(`Failed to create product: ${body}`);
		}
	};

	return (
		<Create title="Create Product">
			<SimpleForm onSubmit={handleSubmit}>
				<TextInput
					source="name"
					fullWidth
					//validate={required()}
					label="Name"
				/>
				<TextInput
					source="description"
					fullWidth
					//validate={required()}
					label="Description"
				/>

				<TextInput
					source="category"
					fullWidth
					//validate={required()}
					label="Category"
				/>

				<NumberInput
					source="priceInCentsUSD"
					fullWidth
					//validate={required()}
					label="Price"
				/>

				<NumberInput
					source="productValue"
					fullWidth
					//validate={required()}
					label="Value"
				/>

				<BooleanInput
					source="isBest"
					fullWidth
					//validate={required()}
					label="Best"
				/>

				<TextInput
					source="imageURL"
					fullWidth
					//validate={required()}
					label="Image url"
				/>

				<JsonInput
					source="bulletPoints"
					format={value => (Array.isArray(value) ? value : [])}
					label='Points' />

				<JsonInput
					source="metadata"
					// format={value => (value !== null && typeof value === 'object' ? value : {})}
					label='Metadata' />


			</SimpleForm>
		</Create>
	);
};
