import {
	List,
	Datagrid,
	TextField,
	Filter,
	FilterLiveSearch,
	Pagination,
	DateField,
	DateInput,
	TextInput,
} from 'react-admin';

const ContentFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="topic" label="Topic" />
		<TextInput source="keywords" label="Keywords" />
		<TextInput source="textType" label="Text Type" />
		<TextInput source="user.email" label="User Email" />
		<DateInput source="creationDate" label="Create Date" />
	</Filter>
);
export const TextList = (props: any) => {
	return (
		<List
			sort={{ field: 'creationDate', order: 'DESC' }}
			{...props}
			resource="admin/contents"
			filters={<ContentFilter />}
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<TextField source="id" />
				<TextField source="topic" />
				<TextField source="keywords" />
				<TextField source="textType" />
				<TextField source="size" />
				<TextField source="user.email" />
				<DateField source="creationDate" sortable showTime={true} />
			</Datagrid>
		</List>
	);
};
