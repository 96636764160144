import React, { useCallback,useState } from 'react';
import {  Button, useRefresh } from 'react-admin';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

const RefundPaymentButton = ({id}) => {
 
  const [isLoading, setIsLoading] = useState(false);
 const PAY = process.env.REACT_APP_HOST2;

  const handleRefundPayment = async () => {
    try {
      setIsLoading(true);      
      const response =  await fetch(`${PAY}/stripe/payment/refund/${id}`, {
        method: 'GET', 
           
      });
       if (response.ok) {
        alert('Payment refunded successfully!');
      } else {
        alert('Refund failed. Please try again later.');
      }
    } catch (error) {
      console.error('Error while refunding payment', error);
      alert('Error while refunding payment. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={isLoading}
      
      onClick={handleRefundPayment}
    >
      <RequestQuoteIcon style={{ marginRight: '8px' }} />
      {isLoading ? 'Loading...' : 'Refund Payment'}
    </Button>
  );
};

export default RefundPaymentButton;



 
/* 
 return (
    <>
      <label
        style={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px 12px',
          borderRadius: '4px',
          background: '#2196F3',
          color: '#ffffff',
          cursor: 'pointer',
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        }}
      >
        <RequestQuoteIcon style={{ marginRight: '8px' }} />
        Refund Payment
        <input
          type="file"
          onChange={handleFileChange}
          style={{
            display: 'none',
          }}
        />
      </label>
    </>
  );
};

export default RefundPaymentButton;
 */