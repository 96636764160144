import { useState } from 'react';
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  useShowController
} from 'react-admin';

export const ChatbotEdit = (props: any) => {
  const [selectedStructure, setSelectedStructure] = useState<any>(null);
  const [availableStyles, setAvailableStyles] = useState<any[]>([]);
  const HOST = process.env.REACT_APP_HOST;

  const handleStructureChange = async (structureId: any) => {
    try {
      const response = await fetch(HOST + `/admin/structure/${structureId}`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      if (response.ok) {
        const structureData = await response.json();
        setSelectedStructure(structureData);
        setAvailableStyles(structureData.styleCss);
      }
    } catch (error) {
      console.error('Error fetching structure data:', error);
    }
  };

  return (
    <Edit mutationMode='pessimistic'>
      <SimpleForm>
        <TextInput source="id" disabled />
        <TextInput source="chatBotName" fullWidth label='Chatbot Name' />
        <TextInput source="askMe" fullWidth label='Ask me' />
        <TextInput source="button" fullWidth label='Button' />
        <TextInput source="tone" fullWidth label='Tone' />
        <TextInput source="footer" fullWidth label='Footer' />
        <BooleanInput source="isActive" fullWidth label='Is active' />

        <ReferenceInput source="structure.id" reference="admin/structure" label="Structure name">
          <SelectInput optionText="name" onChange={event => handleStructureChange(event.target.value)} />
        </ReferenceInput>

        {selectedStructure && (
          <SelectInput
            source="styleCss"
            choices={availableStyles.map(style => ({
              id: style,
              name: style.name,
            }))}
            optionText="name"
            label="Style CSS"
          />
        )}
      </SimpleForm>
    </Edit>
  );
};
