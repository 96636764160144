import {
	Create,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	required,
} from 'react-admin';
import { useNotify, useRedirect } from 'react-admin';

export const StructureCreate = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const HOST = process.env.REACT_APP_HOST;

	const handleSubmit = async (formData: any) => {
		const response = await fetch(HOST + '/admin/structure', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(formData),
		});
		if (response.ok) {
			notify('Structure created successfully');
			redirect('/admin/structure');
		} else {
			const body = await response.json();
			throw new Error(`Failed to create structure: ${body}`);
		}
	};

	return (
		<Create title="Structure script">
			<SimpleForm onSubmit={handleSubmit}>
				<TextInput
					source="name"
					fullWidth
					validate={required()}
					label="Name"
				/>
				<TextInput
					source="imageUrl"
					fullWidth
					label="Logo"
				/>
				
			</SimpleForm>
		</Create>
	);
};

{/* 	<ReferenceInput source="id" reference="admin/users" label="Users">
		<SelectInput optionText="username" validate={required()}/>
	</ReferenceInput> */}
			{/* <TextInput
					source="openAiKey"
					fullWidth
					validate={required()}
					label="openAiKey"
				/>
				<TextInput
					source="reCaptchKey"
					fullWidth
					validate={required()}
					label="reCaptchKey"
				/>
				<TextInput
					source="reCaptchaSecret"
					fullWidth
					validate={required()}
					label="reCaptchaSecret"
				/> */}
