import { useEffect, useState } from 'react';
import {
  Datagrid,
  SimpleShowLayout,
  Show,
  TabbedShowLayout,
  TextField,
  UrlField,
  ArrayField,
  NumberField,
  Tab,
  DateField,
  useShowController
} from 'react-admin';

export const UserShow = (props: any) => {

  const { record } = useShowController(props);
  const [usageInfo, setUsageInfo] = useState({} as any);

  useEffect(() => {
    console.log('record', record);
    console.log("props", props)
    if (record) {
      fetch(`${process.env.REACT_APP_HOST}/admin/chatbots/usage-statistics/${record.email}`,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          }
        })
        .then(res => res.json())
        .then(res => {
          console.log('res', res);
          setUsageInfo(res);
        })
    }

  }, [record]);



  return <Show {...props}>

    <TabbedShowLayout>

      <Tab label="General">
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="domain" fullWidth />
          <TextField source="email" fullWidth />
          <TextField source="resetToken" fullWidth />
          <TextField source="surname" fullWidth />
          <TextField source="username" fullWidth />
          <TextField source="subscriptionId" fullWidth />
          <DateField source="registerDate" fullWidth />
          <DateField source="subscriptionDueDate" fullWidth />
          <DateField source="snippetsDeletionDate" fullWidth />
          <NumberField source="contenterPoints" fullWidth />
          <NumberField source="snippetsAvailable" fullWidth record={usageInfo}/>
          <NumberField source="snippetsCount" fullWidth />
          <NumberField source="requestsPerMonth" fullWidth record={usageInfo}/>
          <NumberField source="requestsCount" fullWidth />
          <NumberField source="maxBotsAllowedToCreate" fullWidth record={usageInfo}/>
          <NumberField source="botsCount" fullWidth record={usageInfo}/>
        </SimpleShowLayout>
      </Tab>


      <Tab label="ChatBots">
        <ArrayField source="chatBots">
          <Datagrid bulkActionButtons={false}>
            <UrlField source="domain" />
            <TextField source="tone" />
            <TextField source="style" />
          </Datagrid>
        </ArrayField>
      </Tab>


      <Tab label="Content">
        <ArrayField source="content">
          <Datagrid bulkActionButtons={false}>
            <UrlField source="keywords" />
            <TextField source="size" />
            <TextField source="topic" />
            <TextField source="text" />
          </Datagrid>
        </ArrayField>
      </Tab>


    </TabbedShowLayout>
  </Show >
}
