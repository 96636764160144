import {
	Create,
	SimpleForm,
	TextInput,
	required,
} from 'react-admin';
import { useNotify, useRedirect } from 'react-admin';

export const PromocodeCreate = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const HOST = process.env.REACT_APP_HOST;

	const handleSubmit = async (formData: any) => {
		const response = await fetch(HOST + '/admin/promocode', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(formData),
		});
		if (response.ok) {
			notify('Style created successfully');
			redirect('/admin/promocode');
		} else {
			const body = await response.json();
			throw new Error(`Failed to create promocode: ${body}`);
		}
	};

	return (
		<Create title="Create Promocode">
			<SimpleForm onSubmit={handleSubmit}>
				<TextInput
					source="discount"
					fullWidth
					validate={required()}
					label="Discount"
				/>
			
			</SimpleForm>
		</Create>
	);
};