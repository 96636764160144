import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { red } from '@mui/material/colors';
import React from 'react';
import {
	List,
	Datagrid,
	TextField,
	Filter,
	FilterLiveSearch,
	Pagination,
	DateField,
	DateInput,
	BooleanField,
	Button,
	useDataProvider,
	useRefresh,
	useNotify,
	TextInput,
	SelectInput,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

const LogFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="id" label="ID" />
		<TextInput source="method" label="Method" />
		{/* 	<TextInput source="role" label="Role" /> */}
		<TextInput source="origin" label="Origin" />
		<SelectInput
			source="role"
			label="Role"
			choices={[
				{ id: '1', name: 'Role 1' },
				{ id: '2', name: 'Role 2' },
			]}
		/>
		<DateInput source="reqDateTime" label="Request Date" />
	</Filter>
);

export const LogList = (props: any) => {
	const dataProvider = useDataProvider();
	const refresh = useRefresh();
	const notify = useNotify();
	const [open, setOpen] = React.useState(false);

	const deleteButtonHandler = () => {
		dataProvider.deleteMany('admin/log', { ids: ['all'] });
		setOpen(false);
		notify('All logs deleted');
		refresh();
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<List
			sort={{ field: 'reqDateTime', order: 'DESC' }}
			resource="admin/log"
			filters={<LogFilter />}
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Button
				label="Delete all logs"
				color="error"
				variant="outlined"
				onClick={handleClick}
				style={{ margin: '16px', backgroundColor: 'red', color: 'white' }}
			/>

			<Dialog open={open} onClose={handleCancel}>
				<DialogTitle>Delete All Logs?</DialogTitle>
				<DialogContent>
					Are you shure that you want to delete all logs?
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancel} label="Cancel" />
					<Button
						onClick={deleteButtonHandler}
						color="primary"
						autoFocus
						label="Confirm"
					/>
				</DialogActions>
			</Dialog>

			<Datagrid rowClick="show" bulkActionButtons={false}>
				<BooleanField source="success" />
				<TextField source="httpStatus" />
				<TextField source="method" />
				<TextField source="url" />
				<TextField source="origin" />
				<TextField source="ip" />
				<DateField source="reqDateTime" showTime={true} />
				<TextField source="executionTime" label="Execution Time (ms)" />
				<TextField source="role" />
			</Datagrid>
		</List>
	);
};
