import jwt_decode from 'jwt-decode';

const HOST = process.env.REACT_APP_HOST;

export const authProvider = {
	login: async ({
		email,
		password,
		rememberMe,
	}: {
		email: string;
		password: string;
		rememberMe: boolean;
	}) => {
		localStorage.removeItem('RaStore.version');
		const request = new Request(HOST + '/auth/login', {
			method: 'POST',
			body: JSON.stringify({ email, password, rememberMe }),
			headers: new Headers({ 'Content-Type': 'application/json' }),
		});

		try {
			const response = await fetch(request);
			if (response.status < 200 || response.status >= 300) {
				alert("You don't have permission");
				localStorage.removeItem('token');
				return Promise.reject({ message: " You don't have permission" });
			}

			const { token } = await response.json();

			if (token) {
				localStorage.setItem('token', token);
				return Promise.resolve({ message: 'Login successful' });
			} else {
				alert('Only admin');
				return Promise.reject({
					message: 'Token not found',
					redirectTo: '/login',
				});
			}
		} catch {
			throw new Error('Network error');
		}
	},
	checkAuth: async (): Promise<any> => {
		const token = localStorage.getItem('token');

		if (!token) {
			localStorage.removeItem('token');
			return Promise.reject({
				message: 'Token not found',
				redirectTo: '/login',
			});
		}
		const decodedToken = jwt_decode(token) as {
			user: { id: number; role: { id: number } };
		};

		const { role } = decodedToken.user;

		if (role.id === 2) {
			return await Promise.resolve({ message: 'Login successful' });
		} else {
			alert('Only admin');
			localStorage.removeItem('token');
			return Promise.reject({
				message: " You don't have permission",
				redirectTo: '/login',
			});
		}
	},
	logout: () => {
		localStorage.removeItem('token');
		return Promise.resolve();
	},

	checkError: (response: { status: number }) => {
		if (response.status === 401) {
			// Redirect to the login page
			localStorage.removeItem('token');
			return Promise.reject({
				redirectTo: '/login',
			});
		}
		return Promise.resolve();
	},
	getPermissions: () => Promise.resolve({}),
};
