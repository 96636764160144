import { Create, SimpleForm, TextInput, required } from 'react-admin';
import { useNotify, useRedirect } from 'react-admin';

export const UserCreate = () => {
	const notify = useNotify();
	const redirect = useRedirect();
	const HOST = process.env.REACT_APP_HOST;

	const RegisterSubmit = async (formData: any) => {
		const response = await fetch(HOST + '/auth/register', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + localStorage.getItem('token'),
			},
			body: JSON.stringify(formData),
		});

		if (response.ok) {
			notify('User registered');
			redirect('/admin/users');
		} else {
			const errors = await response.json();
			throw new Error(`Failed to create user: ${response.statusText}`);
		}
	};

	return (
		<Create title="Create User" redirect="/users">
			<SimpleForm onSubmit={RegisterSubmit}>
				<TextInput source="surname" validate={required()} fullWidth />
				<TextInput source="username" validate={required()} fullWidth />
				<TextInput source="email" validate={required()} fullWidth />
				<TextInput source="password" fullWidth />
			</SimpleForm>
		</Create>
	);
};
