import { ImageList } from '@mui/material';
import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';

const StyleFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="cssName" label="Css name" />
	
	</Filter>
);

export const StyleList = (props: any) => {
	return (
		<List 
			sort={{ field: 'creationDate', order: 'DESC' }}
			filters={<StyleFilter />}
			{...props}
			resource="admin/style"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				
			<ImageField source='imageUrl'  label='Icon'
				sx={{ '& img': { maxWidth: 200 , maxHeight: 200 , objectFit: 'contain' } }}
			/>
				<TextField source="id" />
				<TextField source="cssName" />
				<TextField source="name" />
			</Datagrid>
		</List>
	);
};

