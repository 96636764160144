import {
	BooleanField,
	Datagrid,
	DateField,
	DateInput,
	Filter,
	FilterLiveSearch,
	ImageField,
	List,
	NumberField,
	Pagination,
	TextField,
	TextInput,
	UrlField,
} from 'react-admin';

/* const ProductFilter = (props: any) => (
	<Filter {...props}>
		<TextInput source="name" label="Name" />
		<TextInput source="description" label="Description" />
		<TextInput source="priceInCentsUSD" label="Price" />
		<TextInput source="category" label="Category" />
		<DateInput source="createdAt" label="Create Date" />
	</Filter>
); */



export const ProductList = (props: any) => {

	return (
		<List
			sort={{ field: 'createdAt', order: 'DESC' }}
			//filters={<ProductFilter />}
			{...props}
			resource="admin/product"
			pagination={<Pagination rowsPerPageOptions={[10, 50, 100]} />}
		>
			<Datagrid rowClick="show" bulkActionButtons={false}>
				<ImageField source="imageURL" label='Image url'
					sx={{ '& img': { maxWidth: 70, maxHeight: 70, objectFit: 'contain' } }} />
				<TextField source="id" />
				<TextField source="name" label='Name' />
				<TextField source="description" label='Description' />
				<TextField source="category" label='Category' />
				<BooleanField source="isBest" label='Best' />
				<NumberField source="priceInCentsUSD" label='Price' />
				<NumberField source="productValue" label='Value' />
				<DateField source="createdAt" label='Create date' sortable showTime={true} />
			</Datagrid>
		</List>
	);
};
