import {  Edit,   SimpleForm,  TextInput } from 'react-admin';

export const ScriptEdit = () => (    
    <Edit mutationMode='pessimistic'>
        <SimpleForm>
            <TextInput source="id"  disabled />
            <TextInput source="name" fullWidth label='Name'/>          
            <TextInput source="code"fullWidth label='Code' />
  </SimpleForm>
    </Edit>
);