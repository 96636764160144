import React, { useCallback, useState } from 'react';
import { Button, useRefresh } from 'react-admin';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { useNavigate } from 'react-router-dom';

const FondyRefundPaymentButton = ({ data }) => {
	const [isLoading, setIsLoading] = useState(false);
	const PAY = process.env.REACT_APP_HOST2;
	const navigate = useNavigate();

	const handleRefundPayment = async () => {
		try {
			setIsLoading(true);
			const response = await fetch(`${PAY}/payment/refund`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					order_id: data.order_id,
					amount: data.amount,
					currency: data.currency,
				}),
			});

			const responseData = await response.json();
			if (response.ok) {
				alert('Payment refunded successfully!');
				navigate(-1);
			} else {
				alert('Refund failed. Please try again later.');
			}
		} catch (error) {
			console.error('Error while refunding payment', error);
			alert('Error while refunding payment. Please try again later.');
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Button
			variant="contained"
			color="primary"
			disabled={isLoading}
			onClick={handleRefundPayment}
		>
			<RequestQuoteIcon style={{ marginRight: '8px' }} />
			{isLoading ? 'Loading...' : 'Refund Payment'}
		</Button>
	);
};

export default FondyRefundPaymentButton;
