import { Edit, SimpleForm, TextInput } from "react-admin";

export function EditEmailTemplate() {

    return (
        <Edit mutationMode='pessimistic'>
            <SimpleForm>
                <TextInput source="id" disabled />
                <TextInput source="name" fullWidth label='Name' />
                <TextInput source="subject" fullWidth label='Subject' />
                <TextInput source="template" fullWidth label='Template' />
            </SimpleForm>
        </Edit>
    )

}