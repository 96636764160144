import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    required,
    BooleanInput,
    NumberInput,
} from 'react-admin';

export function CreateEmailTemplate() {


    return (
        <Create >
            <SimpleForm>
                <TextInput
                    source="name"
                    fullWidth
                    validate={required()}
                    label="Name"
                />
                <TextInput
                    source="subject"
                    fullWidth
                    validate={required()}
                    label="Subject"
                />
                <TextInput
                    source="template"
                    fullWidth
                    validate={required()}
                    label="Template"
                />

            </SimpleForm>
        </Create>
    )

}