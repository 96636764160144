import { useState } from "react";
import { Button, Datagrid, List, Pagination, RichTextField, TextField } from "react-admin";

export function ListEmailTemplate(props: any) {

    const [showRichText, setShowRichText] = useState(false);

    const toggleRichText = () => {
        setShowRichText(!showRichText);
    };

    return (
        <>
            <List
                {...props}
                resource="email/template"
                pagination={<Pagination />}
            >
                <Button
                    label={showRichText ? 'Hide Rich Text' : 'Show Rich Text'}
                    variant="outlined"
                    onClick={toggleRichText}
                    style={{ margin: '16px', backgroundColor: 'grey', color: 'white' }}
                />
                <Datagrid rowClick="show" bulkActionButtons={false} size="small">
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="subject" />
                    {showRichText && <RichTextField source="template" />}
                </Datagrid>
            </List>
        </>
    )

}
