import {
	SimpleShowLayout,
	Show,
	TextField,
	UrlField,
	DeleteButton,
	BooleanField,
	NumberField,
	ImageField,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const ProductShow = () => (
	<Show>
		<div style={{ position: 'relative' }}>
			<div
				style={{
					margin: '16px',

					position: 'absolute',
					bottom: 0,
					right: 0,
				}}
			>
				{/* <DeleteButton
					mutationMode="pessimistic"
					style={{
						marginBottom: '16px',
						backgroundColor: 'red',
						color: 'white',
					}}
				/> */}
			</div>
			<SimpleShowLayout>
				<TextField source="id" />
				<TextField source="name" fullWidth label='Name' />
				<TextField source="category" fullWidth label='Сategory' />
				<TextField source="createdAt" fullWidth label='Сreated date' />
				<BooleanField source="isBest" fullWidth label='Best' />
				<NumberField source="productValue" fullWidth label='Product value' />
				<TextField source="description" fullWidth label='Description' />
				<NumberField source="priceInCentsUSD" fullWidth label='Price' />
				<ImageField source="imageURL" fullWidth label='Image url' />
				<JsonField source='bulletPoints' fullWidth label='Points' />
				<JsonField source='metadata' fullWidth label='Metadata' />
			</SimpleShowLayout>
		</div>
	</Show>
);
