import {
	Datagrid,
	DeleteWithConfirmButton,
	Edit,
	List,
	Pagination,
	SaveButton,
	SimpleForm,
	TextField,
	TextInput,
	Toolbar,
} from 'react-admin';

const CustomToolbar = (props: any) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

export const ConfigList = (props: any) => {
	return (
		<Edit mutationMode="pessimistic" {...props} id={1}>
			<SimpleForm toolbar={<CustomToolbar />}>
				<TextInput source="id" label="id" disabled fullWidth />
				<TextInput source="seodescription" label="seodescription" fullWidth />
				<TextInput source="seotitle" label="seotitle" fullWidth />
				<TextInput source="faq" label="faq" fullWidth />
				<TextInput source="conclusion" label="conclusion" fullWidth />
				<TextInput source="excerpt" label="excerpt" fullWidth />
			</SimpleForm>
		</Edit>
	);
};
